/* width */
.scrollbar::-webkit-scrollbar {
    width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
    background: var(--chakra-colors-brand-900);
    border-radius: 10px;
}

.scrollbar {
    float: left;
    width: 100%;
    background: transparent;
    overflow-y: scroll;
}

.overme {
    width: 200px;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
}
